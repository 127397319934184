import React from 'react';
import { X, Trash2 } from 'lucide-react'; // Importer les icônes
import { useCart } from '../context/CartContext'; // Import du contexte de panier
import { Link, useNavigate } from 'react-router-dom';

type CartSidebarProps = {
    isOpen: boolean;
    toggleCart: () => void;
};

const CartSidebar = ({ isOpen, toggleCart }: CartSidebarProps) => {
    const { cartItems, removeFromCart } = useCart(); // Récupérer les articles du panier via le contexte
    const navigate = useNavigate(); // Utiliser useNavigate pour redirection

    // Calcul du total
    const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

    // Fonction pour gérer la redirection vers la page du panier
    const handleCheckout = () => {
        toggleCart(); // Fermer la barre latérale du panier
        navigate('/cartpage'); // Rediriger vers la page du panier
    };

    return (
        <>
            <div
                className={`fixed top-0 right-0 h-full w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-[100] ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            >
                <div className="flex flex-col h-full font-bold">
                    {/* Titre du panier et bouton fermer */}
                    <div className="flex justify-between items-center p-4 border-b">
                        <h2 className="text-xl font-bold">PANIER</h2>
                        <button
                            onClick={toggleCart}
                            className="text-gray-500 hover:text-gray-700"
                            aria-label="Fermer le panier"
                        >
                            <X size={24} />
                        </button>
                    </div>

                    {/* Contenu du panier */}
                    <div className="flex-grow overflow-auto p-4">
                        {cartItems.length === 0 ? (
                            <div className="text-center">
                                <p className="mb-4">Votre panier est vide</p>
                                <Link to="/allproductspage">
                                    <button
                                        className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
                                        onClick={toggleCart}
                                    >
                                        Commencez votre shopping
                                    </button>
                                </Link>
                            </div>
                        ) : (
                            <div className="space-y-4">
                                {cartItems.map((item) => (
                                    <div
                                        key={item.id}
                                        className="flex items-center space-x-4 border-b pb-4"
                                    >
                                        {/* Image de l'article */}
                                        <img
                                            src={item.image}
                                            alt={item.title}
                                            className="w-24 h-24 object-cover rounded-md"
                                        />

                                        {/* Détails de l'article */}
                                        <div className="flex-1">
                                            <h3 className="font-semibold text-lg">{item.title}</h3>
                                            <p className="font-bold">{Number(item.price).toFixed(2)} €</p>
                                            <p className="text-sm text-gray-600">Taille : {item.size}</p>
                                        </div>

                                        {/* Icône de poubelle */}
                                        <button
                                            onClick={() => removeFromCart(item.id)}
                                            className="text-black hover:text-red-600"
                                            aria-label={`Supprimer ${item.title} du panier`}
                                        >
                                            <Trash2 size={20} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Footer avec total et bouton Payer */}
                    {cartItems.length > 0 && (
                        <div className="p-4 border-t">
                            <div className="flex justify-between items-center text-lg font-semibold mb-4">
                                <span>Total :</span>
                                <span>{total.toFixed(2)} €</span>
                            </div>
                            <button
                                onClick={handleCheckout}
                                className="w-full bg-black text-white px-4 py-2 rounded hover:bg-red-600"
                            >
                                Payer
                            </button>
                            <p className="text-xs text-gray-500 mt-2">Taxes incluses.</p>
                            <p className="text-xs text-gray-500">Les frais de livraison seront calculés lors du paiement.</p>
                        </div>
                    )}
                </div>
            </div>

            {/* Overlay pour fermer le panier en cliquant à l'extérieur */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-[90]"
                    onClick={toggleCart}
                ></div>
            )}
        </>
    );
};

export default CartSidebar;
