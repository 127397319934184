import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import HomePageHeader from '../components/HomePageHeader';
import ProductCardV2 from "../components/ProductCardV2";
import GradientButton from "../components/HomePage/GradientButton";
import { IconlyRightArrow } from "../components/icons/IconlyRightArrow";
import { IconlyLeftArrow } from "../components/icons/IconlyLeftArrow";
import Footer from "../components/Footer";
import { getAllProducts } from '../services/api'; // Importation de l'API pour récupérer les produits
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import HeroPresentation from "../components/HomePage/HeroPresentation";
import HeroSteps from "../components/HomePage/HeroSteps";
import VideoAndSocialSection from "../components/HomePage/VideoAndSocialSection";

// Composant pour les flèches du slider
const Arrow = ({ onClick, icon }: { onClick: () => void; icon: React.ReactNode }) => (
    <button onClick={onClick} className="p-2 text-gray-600 hover:bg-gray-100 rounded-full">
        {icon}
    </button>
);

const HomePage = () => {
    const sliderRef = useRef<Slider | null>(null);
    const [products, setProducts] = useState<any[]>([]); // Stocke les produits
    const [isMobile, setIsMobile] = useState(false); // État pour détecter si on est en mode mobile

    useEffect(() => {
        fetchProducts();
        handleResize(); // Appel initial pour déterminer la taille de l'écran
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 530);
    };

    // Récupérer les produits depuis l'API
    const fetchProducts = async () => {
        try {
            const response = await getAllProducts();
            const sortedProducts = response.data
                .filter((product: any) => product.likes_target > 0)  // S'assure que l'objectif de likes existe
                .sort((a: any, b: any) => {
                    const diffA = a.likes_target - a.likes_current;
                    const diffB = b.likes_target - b.likes_current;
                    return diffA - diffB;  // Trier par le plus proche de l'objectif
                });
            setProducts(sortedProducts); // Enregistrer les produits triés
        } catch (error) {
            console.error('Erreur lors de la récupération des produits :', error);
        }
    };

    // Paramètres du slider react-slick
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,  // Affiche un produit par ligne sur mobile
                    arrows: false,      // Désactiver les flèches pour mobile
                }
            }
        ]
    };

    return (
        <div>
            <HomePageHeader/>

            <HeroPresentation/>

            <HeroSteps/>
            <div>
            <main className="px-4 py-4">
                <div className="flex justify-between items-center mb-8 pl-8">
                    <h1 className="text-5xl font-binaria font-bold text-black">
                        LEADER <span className="text-[rgb(228,105,76)]">BOARD</span>
                    </h1>
                </div>

                <div className="flex justify-end space-x-4 mb-4 hidden md-custom:flex">
                    <Arrow onClick={() => sliderRef.current?.slickPrev()}
                           icon={<IconlyLeftArrow size={24} color="#000"/>}/>
                    <Arrow onClick={() => sliderRef.current?.slickNext()}
                           icon={<IconlyRightArrow size={24} color="#000"/>}/>
                </div>

                {/* Afficher les 4 premiers produits en mode mobile */}
                {isMobile ? (
                    <div className="grid grid-cols-1 gap-4">
                        {products.slice(0, 4).map((product) => (
                            <div key={product.id} className="mb-4">
                                <ProductCardV2
                                    id={product.id}
                                    imageSrc={product.image_1}
                                    title={product.name}
                                    price={product.price}
                                    likes={product.likes_current}
                                    likesObjective={product.likes_target}
                                    showAdditionalInfo={true}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <Slider ref={sliderRef} {...settings}>
                        {products.map((product) => (
                            <div key={product.id} className="p-4 md:p-6">
                                <ProductCardV2
                                    id={product.id}
                                    imageSrc={product.image_1}
                                    title={product.name}
                                    price={product.price}
                                    likes={product.likes_current}
                                    likesObjective={product.likes_target}
                                    showAdditionalInfo={true}
                                />
                            </div>
                        ))}
                    </Slider>
                )}

                <br/>
                <div className="mt-8 flex justify-center">
                    <Link to="/allproductspage"> {/* Correction du lien ici */}
                        <GradientButton>
                            LOOK MORE
                        </GradientButton>
                    </Link>
                </div>
                <br/><br/><br/><br/>
                <div className="flex justify-between items-center mb-8 pl-8">
                    <h1 className="text-5xl font-binaria font-bold text-black">
                        NOS <span className="text-[rgb(228,105,76)]">RÉSEAUX</span>
                    </h1>

                </div>
            </main>
                <VideoAndSocialSection />
            </div>
            <Footer/>
        </div>
    );
};

export default HomePage;