import React from 'react';
import { Link } from 'react-router-dom';
import InstaLogo from '../assets/Instagram.png';
import TikTokLogo from '../assets/tiktok.webp';
import LinkedinLogo from '../assets/linkedin.png';
import logo from '../assets/logo_blanc_add_last_transparent.png';

const Footer = () => {
    return (
        <footer className="w-full bg-black text-white py-8 font-binaria">
            {/* Newsletter Section */}
            <div className="w-4/5 mx-auto bg-white text-black p-4 flex flex-col lg:flex-row justify-between items-center rounded-lg">
                <h3 className="text-lg mb-4 lg:mb-0 lg:mr-4 font-semibold">
                    REJOINS LA NEWSLETTER
                </h3>
                <div className="flex flex-1 lg:flex-row flex-col lg:space-x-4 w-full">
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="px-4 py-2 mb-4 lg:mb-0 lg:w-full rounded-md text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    />
                    <button className="bg-[rgb(226,85,53)] text-white px-6 py-2 rounded-md hover:bg-orange-600 w-full lg:w-auto transition-all hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-orange-500">
                        Rejoindre
                    </button>
                </div>
            </div>

            {/* Footer Content */}
            <div className="w-4/5 mx-auto mt-8 grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
                {/* Logo & Socials */}
                <div className="flex flex-col items-start w-full">
                    <img
                        src={logo}
                        alt="Add-Last Logo"
                        className="w-48 h-auto mb-2 ml-2"
                    />
                    <div className="flex space-x-4 mt-4 ml-8">
                        <a href="https://www.instagram.com/add_last" target="_blank" rel="noopener noreferrer">
                            <img
                                src={InstaLogo}
                                alt="Suivez-nous sur Instagram"
                                className="w-8 h-8 hover:opacity-80 transition-opacity"
                            />
                        </a>
                        <a href="rentrer le bon lien" target="_blank" rel="noopener noreferrer">
                            <img
                                src={TikTokLogo}
                                alt="Suivez-nous sur Tiktok"
                                className="w-8 h-8 hover:opacity-80 transition-opacity"
                            />
                        </a>
                        <a href="rentrer le bon lien" target="_blank" rel="noopener noreferrer">
                            <img
                                src={LinkedinLogo}
                                alt="Suivez-nous sur Linkedin"
                                className="w-8 h-8 hover:opacity-80 transition-opacity"
                            />
                        </a>
                    </div>
                </div>

                {/* A PROPOS Section */}
                <div className="text-left flex flex-col w-full">
                    <h4 className="font-bold text-lg mb-4 font-binaria">A PROPOS</h4>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/faq" className="hover:text-[rgb(226,85,53)] transition-colors">
                                FAQ
                            </Link>
                        </li>
                        <li>
                            <Link to="/partnership" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Partenariat
                            </Link>
                        </li>
                        <li>
                            <Link to="/tracking" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Suivi de Colis
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Nous contacter
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Réglementation Section */}
                <div className="text-left flex flex-col w-full">
                    <h4 className="font-binaria font-bold text-lg mb-4">RÈGLEMENTATION</h4>
                    <ul className="space-y-2">
                        <li>
                            <Link to="/legal" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Mentions Légales
                            </Link>
                        </li>
                        <li>
                            <Link to="/delivery-policy" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Politique de Livraison
                            </Link>
                        </li>
                        <li>
                            <Link to="/privacy-policy" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Politique de confidentialité
                            </Link>
                        </li>
                        <li>
                            <Link to="/refund-policy" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Politique de remboursement
                            </Link>
                        </li>
                        <li>
                            <Link to="/terms-of-service" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Conditions Générales de Ventes
                            </Link>
                        </li>
                        <li>
                            <Link to="/terms-of-use" className="hover:text-[rgb(226,85,53)] transition-colors">
                                Conditions d'utilisation
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Divider Line */}
            <div className="w-4/5 mx-auto border-t border-gray-700 mt-8"></div>

            {/* Payment Section */}
            <div className="w-4/5 mx-auto mt-4">
                <h4 className="font-binaria font-bold text-lg mb-4">MOYENS DE PAIEMENT</h4>
                <div className="flex space-x-4">
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="24" rx="4" fill="#000"></rect>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M10.75 15.858H8.69L7.146 9.792c-.074-.279-.23-.526-.458-.642A6.573 6.573 0 0 0 4.8 8.508v-.233h3.318c.458 0 .801.35.859.758l.8 4.375 2.06-5.133h2.002l-3.089 7.583Zm4.234 0h-1.945l1.602-7.584h1.945l-1.602 7.584Zm4.118-5.483c.058-.408.401-.642.802-.642a3.534 3.534 0 0 1 1.888.35l.343-1.633a4.801 4.801 0 0 0-1.773-.35c-1.888 0-3.262 1.05-3.262 2.508 0 1.109.973 1.691 1.66 2.042.743.35 1.03.583.972.933 0 .525-.572.758-1.144.758a4.789 4.789 0 0 1-2.002-.467l-.344 1.634c.687.291 1.43.409 2.117.409 2.117.057 3.433-.992 3.433-2.567 0-1.984-2.69-2.1-2.69-2.975Zm9.498 5.483-1.545-7.584h-1.659a.863.863 0 0 0-.801.584l-2.86 7h2.002l.4-1.108h2.46l.23 1.108H28.6Zm-2.918-5.541.572 2.858h-1.602l1.03-2.858Z"
                              fill="#fff"></path>
                    </svg>
                    {/* Ajoutez d'autres SVG ici */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
