import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { getAllProducts } from '../services/api'; // Adapter selon ta fonction API
import Header from '../components/Header';
import Footer from '../components/Footer';
import Select, { StylesConfig } from 'react-select';
import AddToCartButton from '../components/AddToCartButton';
import { useCart } from '../context/CartContext'; // Utilisation du contexte

interface Product {
    id: number;
    name: string;
    brand: string;
    price: number;
    description: string;
    details: string;
    sizes_stock: { [key: string]: number };
    image_1: string;
    image_2: string;
    image_3: string;
    image_4: string;
    image_5: string;
}

const customStyles: StylesConfig<{ value: string; label: string }, false> = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgb(226,85,53)' : 'white',
        color: state.isFocused ? 'white' : 'black',
        cursor: 'pointer',
    }),
    control: (provided) => ({
        ...provided,
        borderColor: 'gray',
        '&:hover': { borderColor: 'black' },
        boxShadow: 'none',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 100,
    }),
};

export default function ProductPage({ toggleCart }: { toggleCart: () => void }) {
    const { id } = useParams<{ id: string }>();
    const [product, setProduct] = useState<Product | null>(null);
    const [mainImage, setMainImage] = useState<string>('');
    const [selectedSize, setSelectedSize] = useState<string | null>(null);
    const [stockWarning, setStockWarning] = useState<string | null>(null);
    const [sizeError, setSizeError] = useState<string | null>(null); // État pour l'erreur de taille
    const sizeSelectRef = useRef<HTMLDivElement>(null); // Référence vers le sélecteur de taille

    const { addToCart } = useCart();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await getAllProducts();
                const foundProduct = response.data.find((p: Product) => p.id === Number(id));
                if (foundProduct) {
                    setProduct(foundProduct);
                    setMainImage(foundProduct.image_1);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du produit :', error);
            }
        };

        if (id) {
            fetchProduct();
        }
    }, [id]);

    // Fonction pour gérer l'ajout au panier
    const handleAddToBasket = () => {
        if (!selectedSize) {
            setSizeError("Veuillez d'abord choisir une taille");
            sizeSelectRef.current?.scrollIntoView({ behavior: 'smooth' }); // Rediriger vers le sélecteur de taille
        } else if (product) {
            const cartItem = {
                id: product.id,
                title: product.name,
                price: parseFloat(product.price.toString()),
                image: product.image_1,
                size: selectedSize,
                quantity: 1,
            };
            addToCart(cartItem);
            toggleCart(); // Ouvrir le CartSidebar automatiquement
            setSizeError(null); // Réinitialiser l'erreur
        }
    };

    const handleSizeChange = (size: string) => {
        setSelectedSize(size);

        const stock = product?.sizes_stock[size];
        if (stock === 1) {
            setStockWarning(`Il ne reste que 1 article disponible.`);
        } else if (stock && stock < 5) {
            setStockWarning(`Attention ! Il ne reste que ${stock} articles disponibles.`);
        } else {
            setStockWarning(null);
        }
    };

    if (!product) {
        return <div>Chargement...</div>;
    }

    const sizeOptions = Object.keys(product.sizes_stock).map(size => ({
        value: size,
        label: product.sizes_stock[size] === 0 ? `${size} - Rupture de stock` : size,
        isDisabled: product.sizes_stock[size] === 0,
    }));

    return (
        <div>
            <Header />
            <div className="container mx-auto px-4 py-8 font-binaria">
                <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
                    <div className="flex lg:flex-col gap-2 order-2 lg:order-1 lg:col-span-2">
                        {[product.image_1, product.image_2, product.image_3, product.image_4, product.image_5]
                            .filter(image => image)
                            .map((image, index) => (
                                <div key={index} className="relative w-1/5 lg:w-32 flex-shrink-0">
                                    <img
                                        src={image}
                                        alt={`Product Image ${index + 1}`}
                                        className="w-full h-auto rounded-lg cursor-pointer object-cover aspect-square transition-transform duration-300 ease-in-out hover:scale-105"
                                        onMouseEnter={() => setMainImage(image)}
                                        onClick={() => setMainImage(image)}
                                    />
                                </div>
                            ))}
                    </div>

                    <div className="order-1 lg:order-2 lg:col-span-5 mr-8">
                        <img
                            src={mainImage}
                            alt="Main Product Image"
                            className="w-full h-auto rounded-lg object-cover aspect-square transition-opacity duration-300 ease-in-out"
                        />
                    </div>

                    <div className="space-y-6 order-3 lg:order-3 lg:col-span-5">
                        <h1 className="text-3xl font-bold">{product.name}</h1>
                        <p className="text-2xl font-semibold">{product.price} €</p>
                        <div ref={sizeSelectRef} className="space-y-2">
                            <label htmlFor="size-select" className="block text-sm font-medium text-gray-700">
                                Select Size
                            </label>
                            <Select
                                options={sizeOptions}
                                placeholder="Choisissez votre taille"
                                // @ts-ignore
                                editable={ "false"}
                                onChange={(option) => handleSizeChange(option?.value || '')}
                                value={sizeOptions.find(option => option.value === selectedSize)}
                                styles={customStyles}
                            />
                            {sizeError && (
                                <p className="text-red-600 font-semibold">{sizeError}</p>
                            )}
                            {stockWarning && (
                                <p className="text-red-600 font-semibold">{stockWarning}</p>
                            )}
                        </div>


                        <AddToCartButton onClick={handleAddToBasket} label="Ajouter au panier" />
                        <div className="space-y-4">
                            <h2 className="text-xl font-semibold">Description</h2>
                            <p className="text-gray-600">
                                {product.description}
                            </p>
                        </div>

                        <div className="space-y-4">
                            <h2 className="text-xl font-semibold">Product Details</h2>
                            <ul className="list-disc list-inside text-gray-600 space-y-2">
                                {product.details.split('\n').map((detail, index) => (
                                    <li key={index}>{detail}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
