import React from 'react';
// Si tu utilises des icônes externes comme react-icons ou des images locales, importe-les ici
// import { FaFacebook, FaInstagram } from 'react-icons/fa';

const VideoAndSocialSection: React.FC = () => {
    return (
        <section className="w-full mx-auto">
            {/* Partie haute : zone vidéo */}
            <div className="bg-black text-white flex items-center justify-center h-64">
                <h2 className="text-3xl md:text-4xl font-bold">VIDEO</h2>
            </div>

            {/* Partie basse : deux colonnes */}
            <div className="flex flex-col md:flex-row w-full h-auto">

                {/* Colonne gauche */}
                <div className="flex-1 bg-white p-6 flex items-center justify-center">
                    <h3 className="text-2xl md:text-3xl font-bold text-black">
                        REJOINS NOUS SUR LES RÉSEAUX
                    </h3>
                </div>

                {/* Colonne droite */}
                <div className="flex-1 bg-orange-500 p-6 flex flex-col items-center justify-center space-y-6">
                    {/* Cercles avec images */}
                    <div className="flex space-x-6">
                        <div className="w-24 h-24 rounded-full bg-gray-100 overflow-hidden">
                            {/* Remplace la source de l'image ci-dessous par l'URL ou import de ton choix */}
                            <img
                                src="https://via.placeholder.com/150"
                                alt="Cercle 1"
                                className="object-cover w-full h-full"
                            />
                        </div>
                        <div className="w-24 h-24 rounded-full bg-gray-100 overflow-hidden">
                            {/* Remplace la source de l'image ci-dessous par l'URL ou import de ton choix */}
                            <img
                                src="https://via.placeholder.com/150"
                                alt="Cercle 2"
                                className="object-cover w-full h-full"
                            />
                        </div>
                    </div>

                    {/* Icônes de réseaux sociaux */}
                    <div className="flex space-x-6">
                        {/* Si tu utilises des images d'icônes : */}
                        <a
                            href="https://instagram.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="transform hover:scale-110 transition-transform"
                        >
                            <img
                                src="https://via.placeholder.com/32/000000/FFFFFF?text=IG"
                                alt="Instagram"
                                className="w-8 h-8"
                            />
                        </a>
                        <a
                            href="https://facebook.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="transform hover:scale-110 transition-transform"
                        >
                            <img
                                src="https://via.placeholder.com/32/000000/FFFFFF?text=FB"
                                alt="Facebook"
                                className="w-8 h-8"
                            />
                        </a>

                        {/*
              Si tu préfères utiliser react-icons :
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="w-8 h-8 text-white hover:text-gray-200" />
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="w-8 h-8 text-white hover:text-gray-200" />
              </a>
            */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VideoAndSocialSection;
